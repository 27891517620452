/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React, {
  useEffect,
  lazy,
  useContext,
} from 'react';
/// /// EXTERNAL /// ///
// third party librairies //
import TagManager from 'react-gtm-module';
import { Routes, Route, Navigate } from 'react-router-dom';
/// /// INTERNAL /// ///
// components //
import EnhancedSuspense from 'components/atoms/common/EnhancedSuspense';
// context //
import { ErrorContext } from 'context/ErrorContext';
// utils //
import { isProduction } from 'utils/helper';
import ErrorStatus from 'utils/constants';
// components lazy //
const CommonProvider = lazy(() => import('components/providers/CommonProvider'));
const Error = lazy(() => import('./components/templates/Error'));
const Auth = lazy(() => import('components/templates/Auth'));
const UserPlatforms = lazy(() => import('components/molecules/UserPlatforms'));
const AuthGuard = lazy(() => import('components/guard/AuthGuard'));
const LoginGuard = lazy(() => import('components/guard/LoginGuard'));
const Login = lazy(() => import('components/molecules/Login'));
const ForgotPassword = lazy(() => import('components/molecules/ForgotPassword'));
const ChangePassword = lazy(() => import('components/molecules/ChangePassword'));

const App = () => {
  const { setMessage } = useContext(ErrorContext);

  useEffect(() => {
    if (isProduction) {
      const GTM_ID = {
        gtmId: 'GTM-N7C6D27',
      };
      TagManager.initialize(GTM_ID);
    }
    const updated = localStorage.getItem('updated');
    if (updated) {
      setMessage({
        open: true,
        severity: 'success',
        duration: 5000,
        text: 'La aplicación se ha actualizado correctamente.',
      });
      localStorage.removeItem('updated');
    }
  }, [setMessage]);

  return (
    <Routes>
      <Route
        element={(
          <EnhancedSuspense>
            <CommonProvider />
          </EnhancedSuspense>
        )}
      >
        <Route element={<Auth />}>
          <Route
            index
            path="/login"
            element={(
              <LoginGuard>
                <Login />
              </LoginGuard>
            )}
          />
          <Route
            path="/forgot_password"
            element={(
              <LoginGuard>
                <ForgotPassword />
              </LoginGuard>
            )}
          />
          <Route
            path="/change_password"
            element={(
              <ChangePassword />
            )}
          />
          <Route
            path="/platforms"
            element={(
              <AuthGuard>
                <UserPlatforms />
              </AuthGuard>
            )}
          />
        </Route>
        <Route
          path="/error/:status"
          element={(
            <EnhancedSuspense>
              <AuthGuard>
                <Error />
              </AuthGuard>
            </EnhancedSuspense>
          )}
        />
        <Route
          path="*"
          element={(
            <EnhancedSuspense>
              <AuthGuard>
                <Error statusProps={ErrorStatus.NOT_FOUND} />
              </AuthGuard>
            </EnhancedSuspense>
          )}
        />
      </Route>
      <Route exact path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
