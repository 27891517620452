/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React from 'react';
import ReactDOM from 'react-dom';
/// /// EXTERNAL /// ///
// third party libraires //
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/plus-jakarta-sans/200.css';
import '@fontsource/plus-jakarta-sans/300.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/800.css';
/// /// INTERNAL /// ///
// components //
import EnhancedSnackbar from 'components/atoms/common/EnhancedSnackbar';
// context //
import { ErrorProvider } from 'context/ErrorContext';

import App from './App';

// utils //
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(

  <BrowserRouter>
    <ErrorProvider>
      <EnhancedSnackbar />
      <App />
    </ErrorProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// TODO set register when set SW

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
