/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import { Suspense } from 'react';

const EnhancedSuspense = ({ children }) => <Suspense fallback={<>...</>}>{children}</Suspense>;
export default EnhancedSuspense;
