/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
/// /// EXTERNAL /// ///
// third party libraires //
import Cookies from 'js-cookie';
import hexToRgba from 'hex-to-rgba';

const enviroment = process.env.REACT_APP_STAGE || process.env.NODE_ENV;
export const isProduction = enviroment === 'production';

export const REG_EXP_PASS = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#^()?&_-])[A-Za-z\d@$!%*#^()?&_-]{8,}$/;

export const removeCookies = () => {
  Cookies.remove('session_id', {
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
    secure: true,
  });
  Cookies.remove('token', {
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
    secure: true,
  });
  sessionStorage.clear();
};

export const updateCookies = (sessionId, token) => {
  const hours = new Date(new Date().getTime() + 1 * 60 * 60 * 1000);
  Cookies.set('session_id', sessionId, {
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
    secure: true,
    sameSite: 'None',
    expires: hours,
  });
  Cookies.set('token', token, {
    domain: process.env.REACT_APP_COOKIES_DOMAIN,
    secure: true,
    sameSite: 'None',
    expires: hours,
  });
};

export const activeSessionStorage = () => {
  sessionStorage.setItem('active_session', true);
};

export const isPwa = () => ['fullscreen', 'standalone', 'minimal-ui'].some(
  (displayMode) => window.matchMedia(`(display-mode: ${displayMode})`).matches,
);

const rgbLottie = (hex) => {
  const rgba = hexToRgba(hex);

  const current = rgba
    .substring(rgba.indexOf('(') + 1, rgba.lastIndexOf(')'))
    .split(/,\s*/);

  const rgb = {
    r: current[0] / 255,
    g: current[1] / 255,
    b: current[2] / 255,
  };

  return rgb;
};

export const changeColorLottie = (animationData, defaultColor, targetColor) => {
  let animationLottie = { ...animationData };
  const rgbDefault = rgbLottie(defaultColor);
  const rgbTarget = rgbLottie(targetColor);

  const traverse = (data) => {
    const animation = data;
    Object.entries(animation).forEach(([key, value]) => {
      if (animation[key] === rgbDefault.r) animation[key] = rgbTarget.r;
      if (animation[key] === rgbDefault.g) animation[key] = rgbTarget.g;
      if (animation[key] === rgbDefault.b) animation[key] = rgbTarget.b;
      if (value !== null && typeof value === 'object') traverse(value);
    });
  };

  if (
    rgbDefault.r !== rgbTarget.r
    || rgbDefault.g !== rgbTarget.g
    || rgbDefault.b !== rgbTarget.b
  ) {
    const animationJson = JSON.parse(JSON.stringify(animationData));
    traverse(animationJson);
    animationLottie = animationJson;
  }
  return animationLottie;
};
