/**
 * Copyright (C) 2021 Prosperia Salud, S.A. DE C.V. info@prosperia.health.
 * All rights reserved
 * DO NOT REDISTRIBUTE
 * The software MAY NOT be rewritten or refactored by any means. The code MAY
 * NOT be packaged and redistributed by any means without prior written
 * permission from the author.
 */
import React, { createContext, useState, useMemo } from 'react';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [message, setMessage] = useState({
    severity: 'error',
    text: '',
    duration: 0,
    open: false,
  });

  const context = useMemo(() => ({
    message, setMessage,
  }), [message, setMessage]);

  return (
    <ErrorContext.Provider
      value={context}
    >
      {children}
    </ErrorContext.Provider>
  );
};
